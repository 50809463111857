<template>
  <div>
    <div v-if="data" class="field">
      <div class="columns is-multiline is-mobile is-size-7-mobile">
        <div class="column is-6">
          <img
            :src="require('core/img/logos/percayso-logo.svg')"
            alt="Percayso"
            width="130"
            height="28"
          />
        </div>
        <div class="column is-6 has-text-right">
          <span
            class="tag is-danger animated flipInX is-clickable"
            :title="`Valued ${timeAgo(date)}`"
            v-if="isLive(date)"
            v-tippy="{
              placement: 'left',
              inertia: true,
              trigger: 'click',
              sticky: true,
              theme: 'dark',
              hideOnClick: false,
              showOnLoad: true
            }"
          >
            LIVE
          </span>
          <span class="tag is-warning animated flipInX" v-else>
            Valued {{ timeAgo(date) }}
          </span>
        </div>
        <div class="column is-6 has-text-grey">Vehicle description</div>
        <div class="column is-6 has-text-right">{{ description || 'N/A' }}</div>
        <div class="column is-6 has-text-grey">CID</div>
        <div class="column is-6 has-text-right">{{ data.cid || 'N/A' }}</div>
        <div class="column is-6 has-text-grey">Valuation date</div>
        <div class="column is-6 has-text-right">
          {{ formatDate(date) || 'N/A' }}
        </div>
        <div class="column is-6 has-text-grey">Mileage</div>
        <div class="column is-6 has-text-right">
          {{ formatNumber(mileage) || 'N/A' }}
        </div>
        <div class="column is-6 has-text-info">Retail</div>
        <div class="column is-6 has-text-right">
          {{ data.retail ? formatMoney(data.retail.pooled) : 'N/A' }}
        </div>
        <div class="column is-6 has-text-primary">Trade</div>
        <div class="column is-6 has-text-right">
          {{ data.trade ? formatMoney(data.trade) : 'N/A' }}
        </div>
        <div class="column is-12 is-paddingless" v-if="data.onTheRoadPrice">
          <hr class="hr is-marginless" />
        </div>
        <div class="column is-6" v-if="data.onTheRoadPrice">
          <p>Original cost new</p>
          <p class="is-size-7 has-text-grey">% of Original Cost New</p>
        </div>
        <div class="column is-6 has-text-right" v-if="data.onTheRoadPrice">
          <p>
            {{ data.onTheRoadPrice ? formatMoney(data.onTheRoadPrice) : 'N/A' }}
          </p>
          <p class="is-size-7 has-text-grey">
            {{ data.onTheRoadPrice ? `${percentOfOcn}%` : 'N/A' }}
          </p>
        </div>
      </div>
    </div>
    <AutoCheckCompanionUpsell v-bind="{ vrm, cid, date, mileage }" />
  </div>
</template>

<script>
import { getPercentOfOcn } from 'modules/autocheck/services'
import { formatDate, timeAgo, hoursDifference } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckCazana',
  components: {
    AutoCheckCompanionUpsell: () => import('./AutoCheckCompanionUpsell')
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    vrm: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    mileage: {
      type: Number,
      required: true
    }
  },
  computed: {
    percentOfOcn() {
      return getPercentOfOcn({ cazana: this.data })
    },
    depreciation() {
      return this.data.onTheRoadPrice
        ? `${(100 - this.percentOfOcn).toFixed(2)}%`
        : 'N/A'
    },
    cid() {
      return this.data?.cid
    }
  },
  methods: {
    formatDate,
    timeAgo,
    isLive(date) {
      return hoursDifference(Date.now(), date) < 24
    },
    formatMoney(value) {
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0
      }).format(value)
    },
    formatNumber(mileage) {
      return new Intl.NumberFormat('en-UK').format(mileage)
    }
  }
}
</script>
